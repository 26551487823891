export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const DISPATCH_ORDER_REQUEST = "DISPATCH_ORDER_REQUEST";
export const DISPATCH_ORDER_SUCCESS = "DISPATCH_ORDER_SUCCESS";
export const DISPATCH_ORDER_FAILURE = "DISPATCH_ORDER_FAILURE";

export const CONFIRM_DELIVERY_REQUEST = "CONFIRM_DELIVERY_REQUEST";
export const CONFIRM_DELIVERY_SUCCESS = "CONFIRM_DELIVERY_SUCCESS";
export const CONFIRM_DELIVERY_FAILURE = "CONFIRM_DELIVERY_FAILURE";

export const FETCH_PROCESSED_ORDERS_REQUEST = "FETCH_PROCESSED_ORDERS_REQUEST";
export const FETCH_PROCESSED_ORDERS_SUCCESS = "FETCH_PROCESSED_ORDERS_SUCCESS";
export const FETCH_PROCESSED_ORDERS_FAILURE = "FETCH_PROCESSED_ORDERS_FAILURE";

export const CLEAR_ORDERS_DATA = "CLEAR_ORDERS_DATA";
