import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  sideModalOpen,
  sideModalClose,
} from "../../redux/sideModal/modalActions";
import DataTable from "../../components/datatable/DataTable";
import Loader from "../../components/loader/Loader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import TextInput from "../../components/globals/TextInput";
import Button from "../../components/globals/Button";
import { Link } from "react-router-dom";
import {
  fetchCategories,
  registerCategory,
} from "../../redux/product_categories/categoryActions";
import TextAreaInput from "../../components/globals/TextAreaInput";

const CategoriesList = ({ categories, fetchCategories, sideModalState }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );

  const headers = ["Name", "Description", "Edit"];
  const data = categories.categories;
  let tableRows = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      tableRows.push({
        category_name: data[i].category_name,
        category_desc: data[i].category_desc,
        edit: (
          <Link to={`/product-categories/edit-category/${data[i].category_id}`}>
            <i className="bx bx-edit font-14"></i>
          </Link>
        ),
      });
    }
  }

  const inputs = {
    category_name: "",
    category_desc: "",
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    setIsSubmit(true);

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      inputValues.token = token;
      dispatch(registerCategory(inputValues));
      inputValues.category_name = "";
      inputValues.category_desc = "";
      dispatch(sideModalClose());
    }
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.category_name) {
      errors.category_name = "Category name is required!";
    }
    if (!values.category_desc) {
      errors.category_desc = "Description is required!";
    }
    return errors;
  };

  const openModal = () => {
    dispatch(sideModalOpen());
  };

  const displayContent = categories.categories ? (
    categories.loading ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
      {displayContent}

      <SlidingPane
        className="sideModal"
        overlayClassName="sideModal-overlay"
        isOpen={sideModalState.modalOpen}
        title="Product categories"
        subtitle="Add a category"
        width="40%"
        onRequestClose={() => {
          dispatch(sideModalClose());
        }}
      >
        <div className="user-form">
          <form className="form" onSubmit={handleSubmit} autoComplete="off">
            <TextInput
              required="required"
              label="Category name"
              name="category_name"
              type="text"
              value={inputValues.category_name}
              onChange={handleChange}
              inputError={formErrors.category_name}
            />
            <TextAreaInput
              required="required"
              label="Category description"
              name="category_desc"
              value={inputValues.category_desc}
              onChange={handleChange}
              inputError={formErrors.category_desc}
            />
            <Button
              class="btn btn-success bx-pull-right"
              type="submit"
              text={categories.saving ? "Saving..." : "Save"}
              disabled={categories.saving ? "disabled" : ""}
            />
          </form>
        </div>
      </SlidingPane>

      <button onClick={openModal} className="fab_container__button">
        <i className="bx bx-plus"></i>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.productCategories,
    sideModalState: state.sideModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { fetchCategories: () => dispatch(fetchCategories()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
