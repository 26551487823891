import React from "react";
import { Outlet } from "react-router-dom";

const Discounts = () => {
  return (
    <div>
      <div className="title">Discounts</div>
      <Outlet />
    </div>
  );
};

export default Discounts;
