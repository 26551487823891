// import images from "./images"
import avatar from "../assets/images/avatar.jpg";

const data = {
  user: {
    name: "Daniel",
    img: avatar,
  },
  summary: [
    {
      title: "Sales",
      subtitle: "Total sales today",
      value: "$1.000",
      percent: 70,
    },
    {
      title: "Orders",
      subtitle: "Total orders today",
      value: "3000",
      percent: 49,
    },
    {
      title: "Revenue",
      subtitle: "Total revenue today",
      value: "$678",
      percent: 38,
    },
    {
      title: "Rev.(Monthly)",
      subtitle: "January",
      value: "$2345",
      percent: 55,
    },
  ],
  revenueSummary: {
    title: "Revenue",
    value: "$5678",
    chartData: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      data: [300, 300, 280, 370, 200, 300, 280, 350, 200, 300, 280, 350],
    },
  },
  overall: [
    {
      value: "300K",
      title: "Orders",
      icon: <i className="bx bx-receipt"></i>,
    },
    {
      value: "9.876K",
      title: "Customers",
      icon: <i className="bx bx-user"></i>,
    },
    {
      value: "1.234K",
      title: "Products",
      icon: <i className="bx bx-cube"></i>,
    },
    {
      value: "$5678",
      title: "Revenue",
      icon: <i className="bx bx-dollar"></i>,
    },
  ],
  revenueByChannel: [
    {
      title: "Direct sales",
      value: 70,
    },
    {
      title: "Commision",
      value: 40,
    },
    {
      title: "Deliveries",
      value: 60,
    },
  ],
  revenueByMonths: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    data: [250, 200, 300, 280, 100, 220, 310, 190, 200, 120, 250, 350],
  },
};

export default data;
