import { CLOSE_ADD_SIDE_MODAL, OPEN_ADD_SIDE_MODAL } from "./modalActionTypes";

// set the initial state
const initialState = () => {
  return {
    modalOpen: false,
  };
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ADD_SIDE_MODAL:
      return { modalOpen: true };

    case CLOSE_ADD_SIDE_MODAL:
      return { modalOpen: false };

    default:
      return state;
  }
};

export default modalReducer;
