import apiClient from "../ApiClient";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from "./authActionTypes";

// set the actions

// 1. login
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

// 2. set and store the user info in case of success
export const loginSuccess = (userInfo) => {
  return {
    type: LOGIN_SUCCESS,
    payload: userInfo,
  };
};

// set the error message in case of an error
export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

// Registration of the user
export const signupRequest = () => {
  return {
    type: REGISTER_REQUEST,
  };
};

// incase the registration was successful
export const signupSuccess = (user) => {
  return {
    type: REGISTER_SUCCESS,
    payload: user,
  };
};

// incase the registration was unsuccessful
export const signupFailure = (error) => {
  return {
    type: REGISTER_FAILURE,
    payload: error,
  };
};

export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};
// set and store the success message in case of logout success
export const logoutSuccess = (msg) => {
  return {
    type: LOGOUT_SUCCESS,
    payload: msg,
  };
};

// set and store the error message in case of logout failure
export const logoutFailure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    payload: error,
  };
};

// refresh token
export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN,
  };
};

// ----------------------- send to reducer -----------------

// login using axios

export function login(data) {
  return function action(dispatch) {
    dispatch(loginRequest);

    return apiClient
      .post("/api/login", data)
      .then((response) => {
        if (response.data.status === 200) {
          const resp = response.data;
          dispatch(loginSuccess(resp));
          return true;
        } 
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(loginFailure(errorMsg));
        return false;
      });
  };
}

// sign up function
export function signup(data) {
  return function action(dispatch) {
    dispatch(signupRequest);

    return apiClient
      .post("/api/register", data)
      .then((response) => {
        if (response.data.status === 200) {
          const userInfo = response.data;
          dispatch(signupSuccess(userInfo));
          window.history.back();
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(signupFailure(errorMsg));
      });
  };
}

export function logout(token) {
  return function action(dispatch) {
    dispatch(logoutRequest());

    return apiClient
      .post("/api/logout")
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(logoutSuccess(response.data.message));
        }
      })
      .catch((error) => {
        // dispatch(logoutFailure(error.message));
        if (error.response.status === 401) {
          dispatch(refreshToken());
          window.location = "/";
        }

        dispatch(logoutFailure("Failed to log you out. Retry."));
      });
  };
}
