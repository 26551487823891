import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchSalesToday, getMonthlyOrders } from "../../redux/Index";
import { moneyFormat, sumValues } from "../globals/utilityFunctions";
import SummaryBox from "../summary-box/SummaryBox";

const DashboardCards = ({
  dashboardState,
  fetchSalesToday,
  getMonthlyOrders,
}) => {
  useEffect(() => {
    fetchSalesToday();
    getMonthlyOrders();
  }, [fetchSalesToday, getMonthlyOrders]);

  const [salesToday, setSalesToday] = useState([]);
  const [totalSalesToday, setTotalSalesToday] = useState([]);
  const [totalRevenueMonthly, setTotalRevenueMonthly] = useState([]);
  const [grossRevenueMonthly, setGrossRevenueMonthly] = useState([]);

  useEffect(() => {
    const data =
      dashboardState?.salesToday && dashboardState?.salesToday
        ? dashboardState.salesToday.filter((el) => el.order_status === 3)
        : [];
    setSalesToday(data);
    const allOrders =
      dashboardState?.salesToday && dashboardState?.salesToday
        ? dashboardState.salesToday
        : [];
    setTotalSalesToday(allOrders);
    const allMonthlyOrders =
      dashboardState?.monthlySales && dashboardState?.monthlySales
        ? dashboardState.monthlySales
        : [];
    setTotalRevenueMonthly(
      allMonthlyOrders.filter((el) => el.order_status === 3)
    );
    setGrossRevenueMonthly(allMonthlyOrders);
  }, [dashboardState]);

  const totalAmountSold = sumValues(salesToday, "total");
  const totalRevenueMonthlyAmount = sumValues(totalRevenueMonthly, "total");
  const grossRevenueMonthlyAmount = sumValues(grossRevenueMonthly, "total");
  const totalAmountOrdered = sumValues(totalSalesToday, "total");
  let numOfItemsSold = 0;
  if (salesToday) {
    for (let i = 0; i < salesToday.length; i++) {
      let products = JSON.parse(salesToday[i].products);
      numOfItemsSold = sumValues(products, "quantity");
    }
  }
  let totalNumOfItemsOrdered = 0;
  if (totalSalesToday) {
    for (let i = 0; i < totalSalesToday.length; i++) {
      let products = JSON.parse(totalSalesToday[i].products);
      totalNumOfItemsOrdered = sumValues(products, "quantity");
    }
  }

  const date = new Date();
  const currentMonthName = date.toLocaleDateString("default", {
    month: "long",
  });

  const summary = [
    {
      title: "Sales",
      subtitle: "Completed sales today",
      value: Number(numOfItemsSold) + " items",
      percent: (numOfItemsSold / totalNumOfItemsOrdered) * 100,
      showPercentage: true,
    },
    {
      title: "Orders",
      subtitle: "Processed orders today",
      value: salesToday.length,
      percent: (salesToday.length / totalSalesToday.length) * 100,
      showPercentage: true,
    },
    {
      title: "Revenue",
      subtitle: "Total revenue today",
      value: moneyFormat(totalAmountSold),
      percent: (totalAmountSold / totalAmountOrdered) * 100,
      showPercentage: true,
    },
    {
      title: "Rev.(Monthly)",
      subtitle: currentMonthName,
      value: moneyFormat(totalRevenueMonthlyAmount),
      percent: (totalRevenueMonthlyAmount / grossRevenueMonthlyAmount) * 100,
      showPercentage: true,
    },
  ];

  return (
    <div>
      <div className="row">
        {summary.map((item, index) => (
          <div key={`summary-${index}`} className="col-6 col-md-6 col-sm-12 mb">
            <SummaryBox item={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardState: state?.dashboard && state.dashboard ? state.dashboard : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSalesToday: () => dispatch(fetchSalesToday()),
    getMonthlyOrders: () => dispatch(getMonthlyOrders()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCards);
