import React from "react";
import "./searchInput.scss";

const SearchInput = (props) => {
  return (
    <div className="wrap">
      <div className="search">
        <input
          id="search"
          type="text"
          className="searchInput"
          placeholder="Search..."
          onChange={props.search}
        />
        <button type="submit" className="searchButton">
          <i className="fa fa-search"></i>
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
