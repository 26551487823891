import apiClient from "../ApiClient";
import { refreshToken } from "../auth/authActions";
import {
  CLEAR_STATE,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "./userActionTypes";

// set the actions

// 1. fetch the users
export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST,
  };
};

// 2. set and store the users in case of success
export const fetchUsersSuccess = (users) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: users,
  };
};

// set the error message in caseof an error
export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error,
  };
};

// 4.register a user
export const registerUserRequest = () => {
  return {
    type: REGISTER_USER_REQUEST,
  };
};

// 5. set and store the user in case of success
export const registerUserSuccess = (user) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: user,
  };
};

// set the error message in caseof an error
export const registerUserFailure = (error) => {
  return {
    type: REGISTER_USER_FAILURE,
    payload: error,
  };
};

// update a user
export const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

// 5. set and store the user in case of success
export const updateUserSuccess = (msg) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: msg,
  };
};

// set the error message in caseof an error
export const updateUserFailure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
};

// 1clear the state
export const clearStateRequest = () => {
  return {
    type: CLEAR_STATE,
  };
};

// --------------------------- SENDING TO REDUCER ------------------------

// fetch the users using axios
export const fetchUsers = () => {
  return (dispatch) => {
    dispatch(fetchUsersRequest);

    return apiClient
      .get("/api/users")
      .then((response) => {
        if (response.data.status === 200) {
          const users = response.data.users;
          let data = [];
          for (let i = 0; i < users.length; i++) {
            data.push({
              firstname: users[i].firstname,
              lastname: users[i].lastname,
              email: users[i].email,
              phone: users[i].phone,
              id: users[i].xced_id,
              user_type: users[i].user_type,
            });
          }
          dispatch(fetchUsersSuccess(data));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchUsersFailure(errorMsg));
      });
  };
};

export function clearState() {
  return function action(dispatch) {
    dispatch(clearState);
  };
}

// register user
export function registerUser(data) {
  return function action(dispatch) {
    dispatch(registerUserRequest);

    return apiClient
      .post("/api/register", data)
      .then((response) => {
        if (response.data.status === 200) {
          const userInfo = response.data.data;
          const dataArray = {
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            email: userInfo.email,
            phone: userInfo.phone,
            id: userInfo.xced_id,
          };
          dispatch(registerUserSuccess(dataArray));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(registerUserFailure(errorMsg));
      });
  };
}

// update user
export function updateUser(data) {
  return function action(dispatch) {
    dispatch(updateUserRequest);

    return apiClient
      .put("/api/update-user/" + data.xced_id, data)
      .then((response) => {
        if (response.data.status === 200) {
          const success = response.data.message;
          dispatch(updateUserSuccess(success));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateUserFailure(errorMsg));
      });
  };
}
