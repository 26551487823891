import apiClient from "../ApiClient";
import {
  FETCH_DISCOUNTS_FAILURE,
  FETCH_DISCOUNTS_REQUEST,
  FETCH_DISCOUNTS_SUCCESS,
  REGISTER_DISCOUNT_FAILURE,
  REGISTER_DISCOUNT_REQUEST,
  REGISTER_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FAILURE,
  UPDATE_DISCOUNT_REQUEST,
  UPDATE_DISCOUNT_SUCCESS,
} from "./discountActionTypes";

// set the actions

// 1. fetch the discounts
export const fetchDiscountsRequest = () => {
  return {
    type: FETCH_DISCOUNTS_REQUEST,
  };
};

// 2. set and store the discounts in case of success
export const fetchDiscountsSuccess = (discounts) => {
  return {
    type: FETCH_DISCOUNTS_SUCCESS,
    payload: discounts,
  };
};

// set the error message in case of an error
export const fetchDiscountsFailure = (error) => {
  return {
    type: FETCH_DISCOUNTS_FAILURE,
    payload: error,
  };
};

// 4.register a discount
export const addDiscountRequest = () => {
  return {
    type: REGISTER_DISCOUNT_REQUEST,
  };
};

// 5. set and store the discount in case of success
export const addDiscountSuccess = (discount) => {
  return {
    type: REGISTER_DISCOUNT_SUCCESS,
    payload: discount,
  };
};

// set the error message in caseof an error
export const addDiscountFailure = (error) => {
  return {
    type: REGISTER_DISCOUNT_FAILURE,
    payload: error,
  };
};

// update a discount
export const updateDiscountRequest = () => {
  return {
    type: UPDATE_DISCOUNT_REQUEST,
  };
};

// 5. set and store the discount in case of success
export const updateDiscountSuccess = (msg) => {
  return {
    type: UPDATE_DISCOUNT_SUCCESS,
    payload: msg,
  };
};

// set the error message in caseof an error
export const updateDiscountFailure = (error) => {
  return {
    type: UPDATE_DISCOUNT_FAILURE,
    payload: error,
  };
};

// ------------------- Send to reducer ----------------------

// fetch the discounts using axios
export const fetchDiscounts = () => {
  return (dispatch) => {
    dispatch(fetchDiscountsRequest);

    return apiClient
      .get("/api/discounts")
      .then((response) => {
        const discounts = response.data.discounts;
        let data = [];
        for (let i = 0; i < discounts.length; i++) {
          data.push({
            discount_name: discounts[i].discount_name,
            discount_desc: discounts[i].discount_desc,
            discount_percentage: discounts[i].discount_percentage,
            discount_status: discounts[i].discount_status,
            discount_id: discounts[i].discount_id,
          });
        }
        dispatch(fetchDiscountsSuccess(data));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchDiscountsFailure(errorMsg));
      });
  };
};

// register category
export function addDiscount(data) {
  return function action(dispatch) {
    dispatch(addDiscountRequest);

    return apiClient
      .post("/api/addDiscount", data)
      .then((response) => {
        if (response.data.status === 200) {
          const discount = response.data.data;
          const dataArray = {
            discount_name: discount.discount_name,
            discount_desc: discount.discount_desc,
            discount_percentage: discount.discount_percentage,
            discount_status: discount.discount_status,
            discount_id: discount.discount_id,
          };
          dispatch(addDiscountSuccess(dataArray));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(addDiscountFailure(errorMsg));
      });
  };
}

// update discount
export function updateDiscount(data) {
  return function action(dispatch) {
    dispatch(updateDiscountRequest);

    return apiClient
      .put("/api/update_discount/" + data.discount_id, data)
      .then((response) => {
        if (response.data.status === 200) {
          const success = response.data.message;
          dispatch(updateDiscountSuccess(success));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateDiscountFailure(errorMsg));
      });
  };
}
