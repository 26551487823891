export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_IMAGE_REQUEST = "UPDATE_PRODUCT_IMAGE_REQUEST";
export const UPDATE_PRODUCT_IMAGE_SUCCESS = "UPDATE_PRODUCT_IMAGE_SUCCESS";
export const UPDATE_PRODUCT_IMAGE_FAILURE = "UPDATE_PRODUCT_IMAGE_FAILURE";
