import axios from "axios";
import { BASE_URL } from "./api";
import { token } from "./token";

const apiClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  },
});

export default apiClient;
