import React from "react";
import { Link } from "react-router-dom";
import "./auth.scss";
import logo from "../../assets/images/logo.png";

const ForgotPassword = () => {
  return (
    <div className="container">
      <div className="container__panels-container"></div>
      <div className="container__forms-container">
        <div className="container__forms-container__signin-signup">
          <form className="container__forms-container__signin-signup__sign-in-form">
            <div className="logo-container">
              <img src={logo} alt="" />
            </div>
            <h2 className="title-large">Forgot password</h2>
            <div className="input-field">
              <i className="bx bxs-user-circle"></i>
              <input type="email" placeholder="Enter your email address" />
            </div>
            <button type="submit" className="btn solid">
              Request OTP
            </button>
            <div className="form-footer">
              <p>
                Click
                <Link className="links" to="/">
                  &nbsp;here&nbsp;
                </Link>
                to sign in
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
