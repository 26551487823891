import apiClient from "../ApiClient";
import {
  ADD_INVENTORY_FAILURE,
  ADD_INVENTORY_REQUEST,
  ADD_INVENTORY_SUCCESS,
  FETCH_INVENTORIES_FAILURE,
  FETCH_INVENTORIES_REQUEST,
  FETCH_INVENTORIES_SUCCESS,
} from "./inventoryActionTypes";

// set the actions

// 1. fetch the Inventories
export const fetchInventoriesRequest = () => {
  return {
    type: FETCH_INVENTORIES_REQUEST,
  };
};

// 2. set and store the Inventories in case of success
export const fetchInventoriesSuccess = (inventories) => {
  return {
    type: FETCH_INVENTORIES_SUCCESS,
    payload: inventories,
  };
};

// set the error message in caseof an error
export const fetchInventoriesFailure = (error) => {
  return {
    type: FETCH_INVENTORIES_FAILURE,
    payload: error,
  };
};

// 4.Add a Inventory
export const addInventoryRequest = () => {
  return {
    type: ADD_INVENTORY_REQUEST,
  };
};

// 5. set and store the Inventory in case of success
export const addInventorySuccess = (inventory) => {
  return {
    type: ADD_INVENTORY_SUCCESS,
    payload: inventory,
  };
};

// set the error message in case of an error
export const addInventoryFailure = (error) => {
  return {
    type: ADD_INVENTORY_FAILURE,
    payload: error,
  };
};

// fetch the Inventories using axios
export const fetchInventories = (id, token) => {
  return (dispatch) => {
    dispatch(fetchInventoriesRequest);

    return apiClient
      .get("/api/inventories/" + id)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(fetchInventoriesSuccess(response.data.inventories));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchInventoriesFailure(errorMsg));
      });
  };
};

// addInventory
export function addInventory(data, token) {
  return function action(dispatch) {
    dispatch(addInventoryRequest);

    return apiClient
      .post("/api/addInventory", data)
      .then((response) => {
        if (response.data.status === 200) {
          const data = response.data.data;
          dispatch(addInventorySuccess(data));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(addInventoryFailure(errorMsg));
      });
  };
}
