import {
  FETCH_FAILURE,
  FETCH_MONTHLY_SALES_SUCCESS,
  FETCH_ORDERS_ANNUALLY_SUCCESS,
  FETCH_REQUEST,
  FETCH_SALES_TODAY_SUCCESS,
} from "./dashboardActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    salesToday: [],
    monthlySales: [],
    annualSales: [],
    success: "",
    error: "",
  };
};

// set the reducer
const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_SALES_TODAY_SUCCESS:
      return {
        ...state,
        loading: false,
        salesToday: action.payload,
        error: "",
      };
    case FETCH_MONTHLY_SALES_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlySales: action.payload,
        error: "",
      };

    case FETCH_ORDERS_ANNUALLY_SUCCESS:
      return {
        ...state,
        loading: false,
        annualSales: action.payload,
        error: "",
      };

    default:
      return state;
  }
};

export default dashboardReducer;
