import React, { useEffect } from "react";
import "./main-layout.scss";
import "../components/fab/fab.scss";
import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import SystemFooter from "../components/footer/SystemFooter";

const MainLayout = ({ userData }) => {
  // const userData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!userData.isLoggedIn) {
      navigate("/");
    }
    return;
  }, [userData.isLoggedIn]);
  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="main__content">
          <TopNav />
          <Outlet />
          <SystemFooter />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { userData: state?.auth && state.auth ? state.auth : [] };
};

export default connect(mapStateToProps)(MainLayout);
