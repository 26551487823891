import React from "react";
import { Outlet } from "react-router-dom";

const Products = () => {
  return (
    <div>
      {/* <div className="title">Products</div> */}
      <Outlet />
    </div>
  );
};

export default Products;
