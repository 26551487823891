import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./ReducerRoutes";

// persist the state so that it doesnt get lost on page refresh
const persistConfig = {
  key: "misanaAdmin",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// get the reducers from the index
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export default store;

export { persistor };
