import {
  CLEAR_STATE,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "./userActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    users: [],
    success: "",
    error: "",
    saving: false,
    updating: false,
  };
};

// set the reducer
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: "",
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        saving: true,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        saving: false,
        users: [...state.users, action.payload],
        error: "",
      };
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.payload,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        error: "",
        success: action.payload,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload,
        success: "",
      };

    case CLEAR_STATE:
      return {
        loading: false,
        success: "",
        error: "",
        saving: false,
        updating: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
