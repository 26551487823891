import {
  ADD_INVENTORY_FAILURE,
  ADD_INVENTORY_REQUEST,
  ADD_INVENTORY_SUCCESS,
  FETCH_INVENTORIES_FAILURE,
  FETCH_INVENTORIES_REQUEST,
  FETCH_INVENTORIES_SUCCESS,
} from "./inventoryActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    inventories: [],
    success: "",
    error: "",
    saving: false,
  };
};

// set the reducer
const inventoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVENTORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INVENTORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        inventories: action.payload,
        error: "",
      };
    case FETCH_INVENTORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_INVENTORY_REQUEST:
      return {
        ...state,
        saving: true,
        success: "",
      };
    case ADD_INVENTORY_SUCCESS:
      return {
        ...state,
        saving: false,
        success: "Success, record has been saved!",
        inventories: [...state.inventories, action.payload],
        error: "",
      };
    case ADD_INVENTORY_FAILURE:
      return {
        ...state,
        saving: false,
        success: "",
        inventories: state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default inventoriesReducer;
