import React from "react";
import "./footer.scss";

const SystemFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer_container">
      <div className="footer_container__item_container">
        <div className="footer_container__item_container__item">
          © Copyright {currentYear} | All rights Reserved
        </div>
        <div className="footer_container__item">Misana Foods.</div>
      </div>
    </div>
  );
};

export default SystemFooter;
