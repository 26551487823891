import {
  CONFIRM_DELIVERY_FAILURE,
  CONFIRM_DELIVERY_REQUEST,
  CONFIRM_DELIVERY_SUCCESS,
  DISPATCH_ORDER_FAILURE,
  DISPATCH_ORDER_REQUEST,
  DISPATCH_ORDER_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_PROCESSED_ORDERS_FAILURE,
  FETCH_PROCESSED_ORDERS_REQUEST,
  FETCH_PROCESSED_ORDERS_SUCCESS,
} from "./orderActionTypes";

// set the initial state
const initialState = () => {
  return {
    loading: false,
    orders: [],
    processedOrders: [],
    success: "",
    error: "",
  };
};

// set the reducer
const orderReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
        error: "",
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    case FETCH_PROCESSED_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
      };
    case FETCH_PROCESSED_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        processedOrders: action.payload,
        error: "",
      };
    case FETCH_PROCESSED_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    case DISPATCH_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    case DISPATCH_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case DISPATCH_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    case CONFIRM_DELIVERY_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    case CONFIRM_DELIVERY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case CONFIRM_DELIVERY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    default:
      return state;
  }
};

export default orderReducers;
