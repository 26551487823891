const sidebarNav = [
  {
    link: "/home",
    section: "home",
    icon: <i className="bx bx-home-alt"></i>,
    text: "Home",
  },
  {
    link: "/users/list",
    section: "users",
    icon: <i className="bx bx-user-circle"></i>,
    text: "Admin",
  },
  {
    link: "/product-categories/list",
    section: "product-categories",
    icon: <i className="bx bxs-category-alt"></i>,
    text: "Product Categories",
  },
  {
    link: "/discounts/list",
    section: "discounts",
    icon: <i className="bx bxs-discount"></i>,
    text: "Discounts",
  },
  {
    link: "/products/list",
    section: "products",
    icon: <i className="bx bx-cube"></i>,
    text: "Products",
  },
  {
    link: "/orders/list",
    section: "orders",
    icon: <i className="bx bx-receipt"></i>,
    text: "Orders",
  },
  {
    link: "/dispatch/list",
    section: "dispatch",
    icon: <i className="bx bxs-truck"></i>,
    text: "Dispatch",
  },
  {
    link: "/customers/list",
    section: "customers",
    icon: <i className="bx bx-user"></i>,
    text: "Customers",
  },
  {
    link: "/stats",
    section: "stats",
    icon: <i className="bx bx-line-chart"></i>,
    text: "Stats",
  },
  {
    link: "/settings",
    section: "settings",
    icon: <i className="bx bx-cog"></i>,
    text: "Settings",
  },
];

export default sidebarNav;
