import React, { useEffect, useState } from "react";
import "./revenue-list.scss";
// import { data } from "../../constants";
import ProgressBar from "../progressbar/ProgressBar";
import { useSelector } from "react-redux";
import { sumValues } from "../globals/utilityFunctions";

const RevenueList = () => {
  const dashboardState = useSelector((state) =>
    state?.dashboard ? state.dashboard : []
  );

  const [salesToday, setSalesToday] = useState([]);
  const [totalSalesToday, setTotalSalesToday] = useState([]);

  useEffect(() => {
    const data =
      dashboardState?.salesToday && dashboardState?.salesToday
        ? dashboardState.salesToday.filter((el) => el.order_status === 3)
        : [];
    setSalesToday(data);
    const allOrders =
      dashboardState?.salesToday && dashboardState?.salesToday
        ? dashboardState.salesToday
        : [];
    setTotalSalesToday(allOrders);
  }, [dashboardState]);

  const totalAmountSold = sumValues(salesToday, "total");
  const totalAmountOrdered = sumValues(totalSalesToday, "total");
  const directSales = (totalAmountSold / totalAmountOrdered) * 100;

  const revenueByChannel = [
    {
      title: "Direct sales",
      value: isNaN(directSales) ? 0 : directSales,
    },
  ];
  return (
    <ul className="revenue-list">
      {revenueByChannel.map((item, index) => (
        <li className="revenue-list__item" key={`revenue-${index}`}>
          <div className="revenue-list__item__title">
            {item.title}
            <span className={item.value < 50 ? "txt-danger" : "txt-success"}>
              {item.value.toFixed(0)}%
            </span>
          </div>
          {/* progress bar */}
          <div>
            <ProgressBar
              bgColor={item.value > 50 ? "green_bg" : "red_bg"}
              value={item.value.toFixed(0)}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default RevenueList;
