import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./scss/App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blank from "./pages/Blank";
import Dashboard from "./pages/Dashboard";
import MainLayout from "./layout/MainLayout";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import EditUser from "./components/users/EditUser";
import EditCategory from "./components/categories/EditCategory";
import ProductCategories from "./pages/ProductCategories";
import Discounts from "./pages/Discounts";
import EditDiscount from "./components/discounts/EditDiscount";
import Products from "./pages/Products";
import ProductsList from "./components/products/ProductsList";
import Inventory from "./components/products/Inventory";
import EditProduct from "./components/products/EditProduct";
import ProtectedRoute from "./configs/ProtectedRoute";
import CategoriesList from "./components/categories/CategoriesList";
import DiscountsList from "./components/discounts/DiscountsList";
import Orders from "./pages/Orders";
import OrdersList from "./components/orders/OrdersList";
import DeliveredOrders from "./components/orders/DeliveredOrders";
import AssignedOrders from "./components/orders/AssignedOrders";
import FailedOrders from "./components/orders/FailedOrders";
import NewUnassignedOrders from "./components/orders/NewOrders";
import OrdersOnprogress from "./components/orders/OrdersOnprogress";
import Dispatch from "./pages/Dispatch";
import DispatchedOrders from "./components/dispatch/DispatchedOrders";
import NewOrders from "./components/dispatch/NewOrders";
import Customers from "./pages/Customers";
import CustomersList from "./components/customers/CustomersList";
import EditCustomer from "./components/customers/EditCustomer";
import UsersList from "./components/users/UsersList";
import Users from "./pages/Users";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route path="users" element={<Users />}>
            <Route path="list" element={<UsersList />} />
            <Route path="edit/:id" element={<EditUser />} />
          </Route>

          <Route path="orders" element={<Orders />}>
            <Route path="list" element={<OrdersList />} />
            <Route path="new" element={<NewUnassignedOrders />} />
            <Route path="onprogress" element={<OrdersOnprogress />} />
            <Route path="assigned" element={<AssignedOrders />} />
            <Route path="delivered" element={<DeliveredOrders />} />
            <Route path="failed" element={<FailedOrders />} />
          </Route>

          <Route path="product-categories" element={<ProductCategories />}>
            <Route path="list" element={<CategoriesList />} />
            <Route path="edit-category/:id" element={<EditCategory />} />
          </Route>

          <Route path="discounts" element={<Discounts />}>
            <Route path="list" element={<DiscountsList />} />
            <Route path="edit-discount/:id" element={<EditDiscount />} />
          </Route>

          <Route path="products" element={<Products />}>
            <Route path="list" element={<ProductsList />} />
            <Route path="edit-product/:id" element={<EditProduct />} />
            <Route path="inventory/:id" element={<Inventory />} />
          </Route>

          <Route path="dispatch" element={<Dispatch />}>
            <Route path="list" element={<NewOrders />} />
            <Route path="dispatched-orders" element={<DispatchedOrders />} />
          </Route>

          <Route path="customers" element={<Customers />}>
            <Route path="list" element={<CustomersList />} />
            <Route path="edit/:id" element={<EditCustomer />} />
          </Route>

          <Route path="customers" element={<Blank />} />
          <Route path="stats" element={<Blank />} />
          <Route path="settings" element={<Blank />} />
        </Route>

        <Route index path="/" element={<Login />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
