import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  sideModalOpen,
  sideModalClose,
} from "../../redux/sideModal/modalActions";
import DataTable from "../../components/datatable/DataTable";
import Loader from "../../components/loader/Loader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {
  clearOrdersData,
  confirmDelivery,
  fetchProcessedOrders,
} from "../../redux/Index";
import moment from "moment";
import OrderStatus from "../globals/OrderStatus";
import { Link } from "react-router-dom";

const DispatchedOrders = ({ orders, fetchProcessedOrders, sideModalState }) => {
  useEffect(() => {
    fetchProcessedOrders();
  }, [fetchProcessedOrders]);

  const dispatch = useDispatch();

  const [items, setItems] = useState();
  const [orderId, setOrderId] = useState();
  const [total, setTotal] = useState();
  const [err, setError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const openModal = (orderItems, orderID, total) => {
    setItems(orderItems);
    setOrderId(orderID);
    setTotal(total);
    dispatch(sideModalOpen());
    setIsSubmit(false);
  };

  const headers = [
    "Order ID",
    "Order date",
    "Dispatch date",
    "Customer",
    "Customer contact",
    "Delivery address",
    "Status",
    "Confirm delivery",
  ];
  const data = orders?.orders.filter((e) => e.order_status === 1);
  let tableRows = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      tableRows.push({
        order_id: data[i].order_id,
        created_at: moment(data[i].created_at).format("llll"),
        dispatched_at: moment(data[i].dispatched_at).format("llll"),
        customer: data[i].firstname + " " + data[i].lastname,
        contact: data[i].phone,
        location: data[i].location,
        order_status: <OrderStatus status={data[i].order_status} />,
        products: (
          <i
            onClick={() =>
              openModal(
                JSON.parse(data[i].products),
                data[i].order_id,
                data[i].total
              )
            }
            title="View order items"
            className="bx bx-link-external font-14 links"
          ></i>
        ),
      });
    }
  } else {
    tableRows = [];
  }

  //submit the form
  const handleSubmit = () => {
    setIsSubmit(true);
    setError("");

    if (orderId !== "") {
      dispatch(confirmDelivery(orderId));
      dispatch(clearOrdersData());
      setIsSubmit(false);
      setError("");
    } else {
      setIsSubmit(false);
      setError("Something went wrong! retry");
    }
  };

  const displayContent = orders.orders ? (
    orders.loading ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
      <div className="box-header">
        <h3>Dispatched Orders</h3>
        <Link to="/dispatch/list">
          <i className="bx bx-paper-plane"></i> New Orders
        </Link>
      </div>
      {displayContent}

      <SlidingPane
        className="sideModal"
        overlayClassName="sideModal-overlay"
        isOpen={sideModalState.modalOpen}
        title="Dispatch"
        subtitle="Confirm delivery"
        width="30%"
        onRequestClose={() => {
          dispatch(sideModalClose());
        }}
      >
        <div className="user-form">
          <p className="txt-success font-11">{orders.success}</p>
          <p className="txt-danger font-11">{orders.error}</p>
          <p className="txt-danger font-11">{err}</p>
          <div className="clearfix"></div>
          <h4>Order ID: {orderId}</h4>
          {items &&
            items.map((item) => {
              return (
                <div
                  key={item.product_id}
                  className="checkout-wrapper__cart-summary__item__product"
                >
                  <h5>{item.product_name}</h5>
                  <div className="checkout-wrapper__cart-summary__item__product__summation">
                    <p>
                      {item.quantity} item(s) x Kshs.
                      {item.product_price.toFixed(2)}
                    </p>
                    <h6>
                      Kshs.{(item.quantity * item.product_price).toFixed(2)}
                    </h6>
                  </div>
                </div>
              );
            })}
          <div className="clearfix"></div>
          <div className="display-flex space-between">
            <h6 className="pull-right font-10">
              {items?.length && items.length ? items.length : 0} Items
            </h6>
            <h5>Total: {total ? total.toFixed(2) : 0}</h5>
          </div>
          <div className="clearfix border-bottom"></div>
          <button
            text={isSubmit ? "Confirming..." : "Confirm delivery"}
            disabled={isSubmit ? "disabled" : ""}
            className="btn btn-success bx-pull-right"
            onClick={handleSubmit}
          >
            {isSubmit ? "Confirming..." : "Confirm delivery"}
          </button>
        </div>
      </SlidingPane>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state?.orders && state.orders ? state.orders : [],
    sideModalState: state.sideModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProcessedOrders: () => dispatch(fetchProcessedOrders()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchedOrders);
