import React from "react";
import { Bar } from "react-chartjs-2";
import Box from "../components/box/Box";
import DashboardWrapper, {
  DashboardWrapperMain,
  DashboardWrapperRight,
} from "../components/dashboard-wrapper/DashboardWrapper";
import { SummaryBoxSpecial } from "../components/summary-box/SummaryBox";
import { colors, data } from "../constants";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import OverallList from "../components/overall-list/OverallList";
import RevenueList from "../components/revenue-list/RevenueList";
import DashboardCards from "../components/dashboard-wrapper/DashboardCards";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  return (
    <div>
      <DashboardWrapper>
        <DashboardWrapperMain>
          <div className="row">
            <div className="col-12">
              <DashboardCards />
            </div>
            <div className="col-12 md">
              <SummaryBoxSpecial item={data.revenueSummary} />
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="row">
            <div className="col-12">
              <Box>
                <RevenueByMonthsChart />
              </Box>
            </div>
          </div>
        </DashboardWrapperMain>
        <DashboardWrapperRight>
          <div className="title-small mb">Overall</div>
          <div className="mb">
            <OverallList />
          </div>
          <div className="title-small mb">Revenue by channel</div>
          <div className="mb">
            <RevenueList />
          </div>
        </DashboardWrapperRight>
      </DashboardWrapper>
    </div>
  );
};

export default Dashboard;

const RevenueByMonthsChart = () => {
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      yAxes: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    elements: {
      bar: {
        backgroundColor: colors.orange,
        borderRadius: 20,
        borderSkipped: "bottom",
      },
    },
  };

  const chartData = {
    labels: data.revenueByMonths.labels,
    datasets: [
      {
        label: "Revenue",
        data: data.revenueByMonths.data,
      },
    ],
  };
  return (
    <>
      <div className="title mb">Revenue by months</div>
      <div>
        <Bar options={chartOptions} data={chartData} height={`300px`} />
      </div>
    </>
  );
};
