import React from "react";
import "./loader.scss";
import spinner from "../../assets/images/spinner.png";

const Loader = () => {
  return (
    <div className="loaderWrapper">
      <i className="bx bx-spin">
        <img src={spinner} alt="" />
      </i>
    </div>
  );
};

export default Loader;
