import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchProducts,
  fetchUsers,
  getOrdersAnnually,
} from "../../redux/Index";
import { moneyFormat, sumValues } from "../globals/utilityFunctions";
import "./overall-list.scss";

const OverallList = ({
  dashboardState,
  userData,
  productsState,
  fetchUsers,
  fetchProducts,
  getOrdersAnnually,
}) => {
  const [totalSalesToday, setTotalSalesToday] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [annualSales, setAnnualSales] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchProducts();
    getOrdersAnnually();
  }, [fetchUsers, fetchProducts, getOrdersAnnually]);

  useEffect(() => {
    // set aorders
    const allOrders =
      dashboardState?.salesToday && dashboardState?.salesToday
        ? dashboardState.salesToday
        : [];
    setTotalSalesToday(allOrders);
    // set customers
    const users =
      userData?.users && userData.users
        ? userData.users.filter((el) => el.user_type > 1)
        : [];
    setCustomers(users);

    // set products
    const products =
      productsState?.products && productsState.products
        ? productsState.products
        : [];
    setProducts(products);
    const annualOrders =
      dashboardState?.annualSales && dashboardState?.annualSales
        ? dashboardState.annualSales
        : [];
    setAnnualSales(annualOrders.filter((el) => el.order_status === 3));
  }, [dashboardState, productsState, userData]);

  const totalAnnualSales = sumValues(annualSales, "total");

  const data = [
    {
      value: totalSalesToday.length,
      title: "All orders today",
      icon: <i className="bx bx-receipt"></i>,
    },
    {
      value: customers.length,
      title: "Customers",
      icon: <i className="bx bx-user"></i>,
    },
    {
      value: products.length,
      title: "Products",
      icon: <i className="bx bx-cube"></i>,
    },
    {
      value: moneyFormat(totalAnnualSales),
      title: "Annual Revenue",
      icon: <i className="bx bx-dollar"></i>,
    },
  ];
  return (
    <ul className="overall-list">
      {data.map((item, index) => (
        <li className="overall-list__item" key={`overall-${index}`}>
          <div className="overall-list__item__icon">{item.icon}</div>
          <div className="overall-list__item__info">
            <span>{item.title}</span>
            <div className="title-small">{item.value}</div>
          </div>
        </li>
      ))}
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardState: state?.dashboard && state.dashboard ? state.dashboard : [],
    userData: state?.users && state.users ? state.users : [],
    productsState: state?.products && state.products ? state.products : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    fetchProducts: () => dispatch(fetchProducts()),
    getOrdersAnnually: () => dispatch(getOrdersAnnually()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverallList);
