import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  sideModalOpen,
  sideModalClose,
} from "../../redux/sideModal/modalActions";
import { fetchUsers } from "../../redux/users/userActions";
import DataTable from "../datatable/DataTable";
import Loader from "../loader/Loader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

const CustomersList = ({ userData, fetchUsers, sideModalState }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  const headers = ["First name", "Last name", "Email", "Phone", "Edit"];
  const data =
    userData?.users && userData.users
      ? userData.users.filter((el) => el.user_type > 1)
      : [];
  let tableRows = [];
  for (let i = 0; i < data.length; i++) {
    tableRows.push({
      firstname: data[i].firstname,
      lastname: data[i].lastname,
      email: data[i].email,
      phone: data[i].phone,
      edit: (
        <i
          className="bx bx-link-external links font-14"
          onClick={() => showCustomer(data[i].id)}
        ></i>
      ),
    });
  }
  const [customer, setCustomer] = useState({});
  const showCustomer = (id) => {
    const user = data.find((x) => x.id === id);
    setCustomer(user);
    openModal();
  };

  const openModal = () => {
    dispatch(sideModalOpen());
  };

  const displayContent = userData.users ? (
    userData.loading ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
      <div className="title">Customers</div>
      {displayContent}

      <SlidingPane
        className="sideModal"
        overlayClassName="sideModal-overlay"
        isOpen={sideModalState.modalOpen}
        title="Customer"
        subtitle="Customer details"
        width="25%"
        onRequestClose={() => {
          dispatch(sideModalClose());
        }}
      >
        <div className="user-form">
          <div className="boxed-div">
            <div className="display-flex space-between">
              <span className="text-bold font-10">Customer:</span>
              <span className="pull-right text-end">{`${customer.firstname} ${customer.lastname}`}</span>
            </div>
          </div>
          <div className="boxed-div mb">
            <div className="display-flex space-between">
              <span className="text-bold font-10">Email:</span>
              <span className="pull-right text-end">{`${customer.email}`}</span>
            </div>
          </div>
          <div className="boxed-div mb">
            <div className="display-flex space-between">
              <span className="text-bold font-10">Phone:</span>
              <span className="pull-right text-end">{`${customer.phone}`}</span>
            </div>
          </div>
        </div>
      </SlidingPane>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state?.users && state.users ? state.users : [],
    sideModalState: state.sideModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { fetchUsers: () => dispatch(fetchUsers()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);
