//
//      Combime all the reducers and export as one to the store.js
//
import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import categoriesReducer from "./product_categories/categoryReducers";
import modalReducer from "./sideModal/modalReducers";
import usersReducer from "./users/usersReducers";
import discountReducer from "./discounts/discountReducers";
import productReducer from "./products/productReducers";
import inventoriesReducer from "./inventory/inventoryReducers";
import orderReducers from "./orders/orderReducers";
import dashboardReducer from "./dashboard/dashboardReducers";

export const rootReducer = combineReducers({
  users: usersReducer,
  auth: authReducer,
  sideModal: modalReducer,
  productCategories: categoriesReducer,
  discounts: discountReducer,
  products: productReducer,
  inventories: inventoriesReducer,
  orders: orderReducers,
  dashboard: dashboardReducer,
});

export default rootReducer;
