import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  sideModalOpen,
  sideModalClose,
} from "../../redux/sideModal/modalActions";
import DataTable from "../../components/datatable/DataTable";
import Loader from "../../components/loader/Loader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import TextInput from "../../components/globals/TextInput";
import Button from "../../components/globals/Button";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchDiscounts,
  addDiscount,
} from "../../redux/discounts/discountActions";
import TextAreaInput from "../../components/globals/TextAreaInput";

const DiscountsList = ({ discounts, fetchDiscounts, sideModalState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );
  useEffect(() => {
    fetchDiscounts();
  }, [fetchDiscounts]);

  const headers = ["Name", "Percentage", "Description", "Status", "Edit"];
  const data = discounts.discounts;
  let tableRows = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      tableRows.push({
        discount_name: data[i].discount_name,
        discount_percentage: data[i].discount_percentage,
        discount_desc: data[i].discount_desc,
        discount_status: data[i].discount_status > 0 ? "Active" : "Inactive",
        edit: (
          <Link to={`/discounts/edit-discount/${data[i].discount_id}`}>
            <i className="bx bx-edit font-14"></i>
          </Link>
        ),
      });
    }
  } else {
    tableRows = [];
  }

  const inputs = {
    discount_name: "",
    discount_percentage: "",
    discount_desc: "",
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));

    if (Object.keys(formErrors).length === 0) {
      inputValues.token = token;
      dispatch(addDiscount(inputValues));
      inputValues.discount_name = "";
      inputValues.discount_percentage = "";
      inputValues.discount_desc = "";
      window.setTimeout(function () {
        dispatch(sideModalClose());
      }, 3000);
    }
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.discount_name) {
      errors.discount_name = "Discount name is required!";
    }
    if (!values.discount_percentage) {
      errors.discount_percentage = "Discount percentage is required!";
    }
    if (!values.discount_desc) {
      errors.discount_desc = "Description is required!";
    }
    return errors;
  };

  const openModal = () => {
    dispatch(sideModalOpen());
  };

  const displayContent = discounts.discounts ? (
    discounts.loading ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
      {displayContent}

      <SlidingPane
        className="sideModal"
        overlayClassName="sideModal-overlay"
        isOpen={sideModalState.modalOpen}
        title="Discounts"
        subtitle="Add a discount"
        width="40%"
        onRequestClose={() => {
          dispatch(sideModalClose());
        }}
      >
        <div className="user-form">
          <form className="form" onSubmit={handleSubmit} autoComplete="off">
            <TextInput
              required="required"
              label="Discount name"
              name="discount_name"
              type="text"
              value={inputValues.discount_name}
              onChange={handleChange}
              inputError={formErrors.discount_name}
            />
            <TextInput
              required="required"
              label="Discount percentage"
              name="discount_percentage"
              type="number"
              value={inputValues.discount_percentage}
              onChange={handleChange}
              inputError={formErrors.discount_percentage}
            />
            <TextAreaInput
              required="required"
              label="Discount description"
              name="discount_desc"
              value={inputValues.discount_desc}
              onChange={handleChange}
              inputError={formErrors.discount_desc}
            />
            <Button
              class="btn btn-success bx-pull-right"
              type="submit"
              text={discounts.saving ? "Saving..." : "Save"}
              disabled={discounts.saving ? "disabled" : ""}
            />
          </form>
        </div>
      </SlidingPane>

      <button onClick={openModal} className="fab_container__button">
        <i className="bx bx-plus"></i>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    discounts: state.discounts,
    sideModalState: state.sideModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { fetchDiscounts: () => dispatch(fetchDiscounts()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsList);
