import apiClient from "../ApiClient";
import {
  FETCH_FAILURE,
  FETCH_MONTHLY_SALES_SUCCESS,
  FETCH_ORDERS_ANNUALLY_SUCCESS,
  FETCH_REQUEST,
  FETCH_SALES_TODAY_SUCCESS,
} from "./dashboardActionTypes";

///////////////////////////// Daily sales /////////////////////////////

// fetch request
export const fetchRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

// set and store the SalesToday in case of success
export const fetchSalesTodaySuccess = (sales) => {
  return {
    type: FETCH_SALES_TODAY_SUCCESS,
    payload: sales,
  };
};

// set and store the Orders Annually in case of success
export const fetchOrdersAnnuallySuccess = (sales) => {
  return {
    type: FETCH_ORDERS_ANNUALLY_SUCCESS,
    payload: sales,
  };
};

// set the error message in caseof an error
export const fetchFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};

//////////////////////////// Monthly sales ///////////////////////////

// set and store the MonthlySales in case of success
export const fetchMonthlySalesSuccess = (sales) => {
  return {
    type: FETCH_MONTHLY_SALES_SUCCESS,
    payload: sales,
  };
};

// fetch the SalesToday
export const fetchSalesToday = () => {
  return (dispatch) => {
    dispatch(fetchRequest);

    return apiClient
      .get("/api/getTodaysOrders")
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(fetchSalesTodaySuccess(response.data.data));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchFailure(errorMsg));
      });
  };
};

// fetch the monthly orders
export const getMonthlyOrders = () => {
  return (dispatch) => {
    dispatch(fetchRequest);

    return apiClient
      .get("/api/getCurrentMonthOrders")
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(fetchMonthlySalesSuccess(response.data.data));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchFailure(errorMsg));
      });
  };
};

// fetch the annual orders
export const getOrdersAnnually = () => {
  return (dispatch) => {
    dispatch(fetchRequest);

    return apiClient
      .get("/api/getOrdersAnnually")
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(fetchOrdersAnnuallySuccess(response.data.data));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchFailure(errorMsg));
      });
  };
};
