import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  sideModalOpen,
  sideModalClose,
} from "../../redux/sideModal/modalActions";
import DataTable from "../datatable/DataTable";
import Loader from "../loader/Loader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { fetchOrders } from "../../redux/Index";
import moment from "moment";
import OrderStatus from "../globals/OrderStatus";
import "./orders.scss";
import { useLocation } from "react-router-dom";
import OrdersMenu from "./OrdersMenu";

const OrdersOnprogress = ({ orders, fetchOrders, sideModalState }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );

  const [items, setItems] = useState();
  const [orderId, setOrderId] = useState();
  const [total, setTotal] = useState();

  const openModal = (orderItems, orderID, total) => {
    setItems(orderItems);
    setOrderId(orderID);
    setTotal(total);
    dispatch(sideModalOpen());
  };
  useEffect(() => {
    fetchOrders(token);
  }, [fetchOrders]);

  const headers = [
    "Order ID",
    "Date",
    "Customer",
    "Contact",
    "Delivery address",
    "Status",
    "Products",
  ];
  const data = orders.orders.filter((x) => x.order_status === 2);
  let tableRows = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      tableRows.push({
        order_id: data[i].order_id,
        created_at: moment(data[i].created_at).format("llll"),
        customer: data[i].firstname + " " + data[i].lastname,
        contact: data[i].phone,
        location: data[i].location,
        order_status: <OrderStatus status={data[i].order_status} />,
        products: (
          <i
            onClick={() =>
              openModal(
                JSON.parse(data[i].products),
                data[i].order_id,
                data[i].total
              )
            }
            title="View order items"
            className="bx bx-link-external font-14 links"
          ></i>
        ),
      });
    }
  } else {
    tableRows = [];
  }

  const displayContent = orders.orders ? (
    orders.loading ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  const location = useLocation();
  const getLastItem = (location) =>
    location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

  return (
    <div>
      <div className="box-header">
        <h3>Orders on progress</h3>
      </div>
      <div className="menuist">
        <OrdersMenu path={getLastItem(location)} />
      </div>
      {displayContent}

      <SlidingPane
        className="sideModal"
        overlayClassName="sideModal-overlay"
        isOpen={sideModalState.modalOpen}
        title="Order items"
        subtitle="Order items list"
        width="35%"
        onRequestClose={() => {
          dispatch(sideModalClose());
        }}
      >
        <div className="user-form">
          <h4>Order ID: {orderId}</h4>
          {items &&
            items.map((item) => {
              return (
                <div
                  key={item.product_id}
                  className="checkout-wrapper__cart-summary__item__product"
                >
                  <h5>{item.product_name}</h5>
                  <div className="checkout-wrapper__cart-summary__item__product__summation">
                    <p>
                      {item.quantity} item(s) x Kshs.
                      {item.product_price.toFixed(2)}
                    </p>
                    <h6>
                      Kshs.{(item.quantity * item.product_price).toFixed(2)}
                    </h6>
                  </div>
                </div>
              );
            })}
          <div className="clearfix"></div>
          <div className="display-flex space-between">
            <h6 className="pull-right font-10">
              {items?.length && items.length ? items.length : 0} Items
            </h6>
            <h5>Total: {total}</h5>
          </div>
        </div>
      </SlidingPane>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state?.orders && state.orders ? state.orders : [],
    sideModalState: state.sideModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { fetchOrders: (token) => dispatch(fetchOrders(token)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersOnprogress);
