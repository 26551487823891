import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  sideModalOpen,
  sideModalClose,
} from "../../redux/sideModal/modalActions";
import {
  fetchInventories,
  addInventory,
} from "../../redux/inventory/inventoryActions";
import DataTable from "../../components/datatable/DataTable";
import Loader from "../../components/loader/Loader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import TextInput from "../../components/globals/TextInput";
import Button from "../../components/globals/Button";
import { Link } from "react-router-dom";

// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router";
import Moment from "react-moment";

const Inventory = ({
  inventories,
  fetchInventories,
  products,
  sideModalState,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );

  useEffect(() => {
    fetchInventories(id, token);
  }, [fetchInventories]);

  const headers = [
    "Stock date",
    "Quantity bought",
    "Previous stock",
    "Buying price",
  ];
  const data = inventories.inventories;
  let tableRows = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      tableRows.push({
        created_at: (
          <Moment format="ddd, Do MMM, YYYY">{data[i].created_at}</Moment>
        ),
        quantity: data[i].quantity,
        in_stock: data[i].in_stock,
        buying_price: data[i].buying_price,
      });
    }
  } else {
    tableRows = [];
  }

  let product = products.products.find((e) => e.product_id === Number(id));

  const inputs = {
    quantity: "",
    buying_price: "",
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const notyfy = () => {
    toast.success("Success. Record Saved");
  };
  const loading = () => {
    toast.info("Saving, please wait...");
  };
  const errorNoty = () => {
    toast.error("Operation failed");
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    loading();

    if (Object.keys(formErrors).length === 0) {
      inputValues["product_id"] = id;
      dispatch(addInventory(inputValues, token));

      if (inventories.success !== "") {
        inputValues.quantity = "";
        inputValues.buying_price = "";
        dispatch(sideModalClose());
        notyfy();
      } else {
        // show error
        errorNoty("Operation failed, please retry.");
      }
    }
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.quantity) {
      errors.quantity = "Quantity is required!";
    }
    if (!values.buying_price) {
      errors.buying_price = "Buying price is required!";
    }
    return errors;
  };

  const openModal = () => {
    dispatch(sideModalOpen());
  };

  const displayContent = inventories.inventories ? (
    inventories.loading ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
      <div className="box-header">
        <h4 className="title">Inventory: {product.product_name}</h4>
        <Link to="/products/list">
          <i className="bx bxs-right-arrow-circle"></i>
        </Link>
      </div>
      {displayContent}
      {/* <TimeFormats /> */}
      <SlidingPane
        className="sideModal"
        overlayClassName="sideModal-overlay"
        isOpen={sideModalState.modalOpen}
        title="Inventories"
        subtitle="Add an inventory"
        width="40%"
        onRequestClose={() => {
          dispatch(sideModalClose());
        }}
      >
        <div className="user-form">
          <form className="form" onSubmit={handleSubmit} autoComplete="off">
            <TextInput
              required="required"
              label="Quantity"
              name="quantity"
              type="number"
              value={inputValues.quantity}
              onChange={handleChange}
              inputError={formErrors.quantity}
            />
            <TextInput
              required="required"
              label="Buying price"
              name="buying_price"
              type="number"
              value={inputValues.buying_price}
              onChange={handleChange}
              inputError={formErrors.buying_price}
            />
            <Button
              class="btn btn-success bx-pull-right"
              type="submit"
              text={inventories.saving ? "Saving..." : "Save"}
              disabled={inventories.saving ? "disabled" : ""}
            />
          </form>
        </div>
      </SlidingPane>

      <button onClick={openModal} className="fab_container__button">
        <i className="bx bx-plus"></i>
      </button>
      <ToastContainer theme="dark" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    inventories: state.inventories,
    products: state.products,
    sideModalState: state.sideModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInventories: (id, token) => dispatch(fetchInventories(id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
