import React from "react";
import "./textInput.scss";

const TextInput = (props) => {
  //   return <input name={name} placeholder={label} value={value} type={type} />;
  return (
    <div>
      <div className="field-container">
        <input
          className="field-input"
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          required={props.required}
          autoComplete="off"
          placeholder=" "
        />
        <label className="field-placeholder" htmlFor={props.name}>
          {props.label}
        </label>
        <p className="txt-danger title-small">{props.inputError}</p>
      </div>
    </div>
  );
};

export default TextInput;
