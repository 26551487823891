import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./auth.scss";
import logo from "../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { login } from "../../redux/auth/authActions";
import { useSelector } from "react-redux";
import Button from "../../components/globals/Button";
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);

  let [state, setState] = useState({
    phone: "",
    password: "",
  });

  let handleChange = (e) => {
    const value = e.target.value;
    setState({ ...state, [e.target.name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (state.phone && state.password) {
      toast.info("Signing in...");
      dispatch(login(state)).then((status) => {
        if (status) {
          toast.success("Logged in successfully");
          window.location.href = "/home";
        } else {
          toast.error("Failed to log you in");
        }
      });
    } else {
      toast.error("All fields are required");
    }
  };

  return (
    <div className="container">
      <div className="container__panels-container"></div>
      <div className="container__forms-container">
        <div className="container__forms-container__signin-signup">
          <form
            onSubmit={submitForm}
            className="container__forms-container__signin-signup__sign-in-form"
          >
            <div className="logo-container">
              <img src={logo} alt="" />
            </div>
            <h2 className="title-large">Sign in</h2>
            <div className="input-field">
              <i className="bx bxs-user-circle"></i>
              <input
                onChange={handleChange}
                value={state.phone}
                name="phone"
                type="text"
                placeholder="Email or Phone number"
              />
            </div>
            <div className="input-field">
              <i className="bx bxs-lock "></i>
              <input
                onChange={handleChange}
                value={state.password}
                name="password"
                type="password"
                placeholder="Password"
              />
            </div>
            <Button
              class="btn btn-primary"
              type="submit"
              text={userData.loading ? "Signing in" : "Sign in"}
              disabled={userData.loading ? "disabled" : ""}
            />
            <div className="form-footer">
              <p>
                Forgot password?{" "}
                <Link className="links" to="/forgotpassword">
                  Reset
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
};

export default Login;
