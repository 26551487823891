import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  sideModalOpen,
  sideModalClose,
} from "../../redux/sideModal/modalActions";
import { fetchUsers, registerUser } from "../../redux/users/userActions";
import DataTable from "../datatable/DataTable";
import Loader from "../loader/Loader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import TextInput from "../globals/TextInput";
import Button from "../globals/Button";
import { Link } from "react-router-dom";

const UsersList = ({ userData, fetchUsers, sideModalState }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  const headers = ["First name", "Last name", "Email", "Phone", "Edit"];
  const data =
    userData?.users && userData.users
      ? userData.users.filter((el) => el.user_type < 2)
      : [];
  let tableRows = [];
  for (let i = 0; i < data.length; i++) {
    tableRows.push({
      firstname: data[i].firstname,
      lastname: data[i].lastname,
      email: data[i].email,
      phone: data[i].phone,
      edit: (
        <Link to={`/users/edit/${data[i].id}`}>
          <i className="bx bx-edit font-14"></i>
        </Link>
      ),
    });
  }

  const inputs = { firstname: "", lastname: "", email: "", phone: "" };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    setIsSubmit(true);

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(registerUser(inputValues));
      dispatch(sideModalClose());
    }
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.firstname) {
      errors.firstname = "First name is required!";
    }
    if (!values.lastname) {
      errors.lastname = "Last name is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Please enter a valid email address!";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required!";
    }
    return errors;
  };

  const openModal = () => {
    dispatch(sideModalOpen());
  };

  const displayContent = userData.users ? (
    userData.loading ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
      <div className="title">System users</div>
      {displayContent}

      <SlidingPane
        className="sideModal"
        overlayClassName="sideModal-overlay"
        isOpen={sideModalState.modalOpen}
        title="Users"
        subtitle="Add a user"
        width="40%"
        onRequestClose={() => {
          dispatch(sideModalClose());
        }}
      >
        <div className="user-form">
          <form className="form" onSubmit={handleSubmit} autoComplete="off">
            <TextInput
              required="required"
              label="First name"
              name="firstname"
              type="text"
              value={inputValues.firstname}
              onChange={handleChange}
              inputError={formErrors.firstname}
            />
            <TextInput
              required="required"
              label="Last name"
              name="lastname"
              type="text"
              value={inputValues.lastname}
              onChange={handleChange}
              inputError={formErrors.lastname}
            />
            <TextInput
              required="required"
              label="Email"
              name="email"
              type="email"
              value={inputValues.email}
              onChange={handleChange}
              inputError={formErrors.email}
            />
            <TextInput
              required="required"
              label="Phone"
              name="phone"
              type="number"
              value={inputValues.phone}
              onChange={handleChange}
              inputError={formErrors.phone}
            />
            <Button
              class="btn btn-success bx-pull-right"
              type="submit"
              text={userData.saving ? "Saving..." : "Save"}
              disabled={userData.saving ? "disabled" : ""}
            />
          </form>
        </div>
      </SlidingPane>

      <button onClick={openModal} className="fab_container__button">
        <i className="bx bx-plus"></i>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state?.users && state.users ? state.users : [],
    sideModalState: state.sideModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { fetchUsers: () => dispatch(fetchUsers()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
