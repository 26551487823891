import React, { useEffect, useMemo, useState } from "react";
import SearchInput from "../globals/SearchInput";
import "./datatable.scss";
import img from "../../assets/images/no-data-found.png";
import Pagination from "./Pagination";
import SelectInput from "../globals/SelectInput";

function DataTable({ headers, data }) {
  let [PageSize, setPageSize] = useState(10);
  // new configs /////////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  let options = [
    { value: 10, label: "10 records" },
    { value: 25, label: "25 records" },
    { value: 50, label: "50 records" },
    { value: data.length, label: "All records" },
  ];
  const [show_num_of_records, setShowNumOfRecords] = useState(10);

  //   handle select field change
  const handleChange = (e) => {
    const val = e.target.value;
    setPageSize(val);
    setShowNumOfRecords(val);
  };

  let currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, PageSize, data]);

  useEffect(() => {
    setPageSize(PageSize);
  }, [PageSize]);

  // new configs /////////////////////////

  const searchData = (e) => {
    let rows = document.querySelectorAll("table > tbody > tr");
    let filter = e.target.value.toLowerCase().trim();
    // Loop through first tbody's rows
    for (let i = 0; i < rows.length; i++) {
      // define the row's cells
      let tds = rows[i].getElementsByTagName("td");

      // hide the row
      rows[i].style.display = "none";

      // loop through row cells
      for (let j = 0; j < tds.length; j++) {
        // if there's a match
        if (tds[j].innerHTML.toLowerCase().indexOf(filter) > -1) {
          // show the row
          rows[i].style.display = "";

          // skip to the next row
          continue;
        }
      }
    }
  };

  const dataTable = currentTableData.length ? (
    currentTableData.map((row, index) => {
      let rowData = [];
      let i = 0;

      for (const key in row) {
        rowData.push({
          key: headers[i],
          val: row[key],
        });
        i++;
      }
      return (
        <tr className="table-row" key={index}>
          {rowData.map((data, index) => (
            <td
              className="col col-4"
              key={index}
              data-label={data.key}
              data-heading={data.key}
            >
              {data.val}
            </td>
          ))}
        </tr>
      );
    })
  ) : (
    <tr className="txt-danger font-12">
      <td colSpan={Object.keys(headers).length}>No records found</td>
    </tr>
  );
  return (
    <div className="table-container">
      <div hidden={data.length ? "" : "hidden"}>
        <div className="clearfix"></div>
        <div
          className={
            currentTableData.length < 6 ? "hidden" : "table-container__header"
          }
        >
          <div className="searhTable">
            <div className="col-12">
              <SelectInput
                label="Show"
                onChange={handleChange}
                options={options}
                name="show_num_of_records"
                value={show_num_of_records}
              />
            </div>
          </div>
          <div className="searchTable">
            <SearchInput search={searchData} />
          </div>
        </div>
        <div className="table-container__body">
          <table className="responsive-table">
            <thead>
              <tr className="table-header">
                {headers.map((col, index) => (
                  <th className="col col-1" key={index}>
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{dataTable}</tbody>
          </table>
        </div>
        <div className="table-container__footer">
          <div className={currentTableData.length < 6 ? "hidden" : "records"}>
            Total records: {data.length}
          </div>
          <div className="pagination">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
      <div className="no-data" hidden={currentTableData.length ? "hidden" : ""}>
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default DataTable;
