import React, { useState } from "react";
import DashboardWrapper, {
  DashboardWrapperMain,
  DashboardWrapperRight,
} from "../../components/dashboard-wrapper/DashboardWrapper";
import OverallList from "../../components/overall-list/OverallList";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../scss/_columns.scss";
import { useParams } from "react-router";
import {
  updateProduct,
  updateProductImage,
} from "../../redux/products/productActions";
import TextInput from "../../components/globals/TextInput";
import TextAreaInput from "../../components/globals/TextAreaInput";
import SelectInput from "../../components/globals/SelectInput";
import { useDispatch } from "react-redux";
import CurrencyFormatter from "../../components/globals/CurrencyFormatter";
import Moment from "react-moment";
import { BASE_URL } from "../../redux/api";

// End of imports //

const EditProduct = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const categories = useSelector((state) => state.productCategories.categories);
  const discounts = useSelector((state) => state.discounts.discounts);
  const { id } = useParams();
  let product = products.find((e) => e.product_id === Number(id));
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );

  let overallData = [
    {
      value: product.product_SKU,
      title: "SKU",
      icon: <i className="bx bx-receipt"></i>,
    },
    {
      value: product.product_status > 0 ? "Active" : "Inactive",
      title: "Product status",
      icon: <i className="bx bx-bolt-circle"></i>,
    },
    {
      value: product.products_available,
      title: "In stock",
      icon: <i className="bx bxs-coin-stack"></i>,
    },
    {
      value: <Moment format="ddd, Do MMM, YYYY">{product.updated_at}</Moment>,
      title: "Last updated",
      icon: <i className="bx bx-time"></i>,
    },
  ];

  let discountVal = 0;
  let discountedVal = 0;

  if (product.discount_id) {
    discountVal = discounts.find(
      (e) => e.discount_id === Number(product.discount_id)
    ).discount_percentage;
    discountedVal = product.product_price * (discountVal / 100);
  } else {
    discountVal = 0;
    discountedVal = 0;
  }

  let sellingData = [
    {
      value: `${discountVal}%`,
      title: "Discount %",
      icon: <i className="bx bx-receipt"></i>,
    },
    {
      value: discountedVal.toFixed(2),
      title: "Discount",
      icon: <i className="bx bx-dollar-circle"></i>,
    },
    {
      value: CurrencyFormatter(product.product_price - discountedVal, 2),
      title: "Final price",
      icon: <i className="bx bx-money"></i>,
    },
  ];

  // NOTIFICATIONS
  const notyfy = () => {
    toast.success("Success. Data Saved");
  };
  const loading = (msg) => {
    toast.info(msg);
  };
  const errorNoty = (msg) => {
    toast.error(msg);
  };

  //  select input options for categories
  let options = [{ value: " ", label: "-- select --" }];
  if (categories) {
    for (let i = 0; i < categories.length; i++) {
      options.push({
        value: categories[i].category_id,
        label: categories[i].category_name,
      });
    }
  }

  //  select input options for discounts
  let discountOptions = [{ value: " ", label: "None" }];
  if (discounts) {
    for (let i = 0; i < discounts.length; i++) {
      discountOptions.push({
        value: discounts[i].discount_id,
        label: discounts[i].discount_name,
      });
    }
  }

  //  select input options for discounts
  let statusOptions = [
    { value: " ", label: "-- select --" },
    { value: 0, label: "Inactive" },
    { value: 1, label: "Active" },
  ];

  const [selectedFile, setSelectedFile] = useState([]);

  const inputs = {
    product_name: product.product_name,
    product_desc: product.product_desc,
    product_price: product.product_price,
    category_id: product.category_id,
    product_status: product.product_status,
    discount_id: product.discount_id,
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleFileChange = (event) => {
    setSelectedFile({ photo: event.target.files[0] });
  };

  const updateProductPhoto = (e) => {
    e.preventDefault();
    if (!selectedFile.photo) {
      errorNoty("Sorry, please select an image");
    } else {
      const formData = new FormData();
      formData.append("photo", selectedFile.photo);

      dispatch(updateProductImage(formData, id, token));
      if (products.success !== "") {
        notyfy();
        loading("Updating, please wait...");
      } else {
        errorNoty("Sorry, operation failed, please try again");
      }
    }
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    toast.info("Updating, please wait...");

    if (Object.keys(formErrors).length === 0) {
      inputValues["product_id"] = id;
      dispatch(updateProduct(inputValues, token));
      window.setTimeout(function () {
        if (products.success !== "") {
          toast.success("Success! Updated successfully!");
        } else if (products.error !== "") {
          toast.error("Failed to update the product. Retry.");
        } else {
          toast.error("Operation failed, please try again");
        }
      }, 3000);
    }
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.product_name) {
      errors.product_name = "product name is required!";
    }
    if (!values.product_price) {
      errors.product_price = "Product price is required!";
    }
    if (!values.product_desc) {
      errors.product_desc = "Product descriprion is required!";
    }
    if (!values.category_id) {
      errors.category_id = "Product category is required!";
    }
    if (!values.product_status) {
      errors.product_status = "Product status is required!";
    }
    return errors;
  };
  return (
    <div>
      <DashboardWrapper>
        <DashboardWrapperMain>
          <h4 className="title">Edit product details</h4>
          <div className="div-wrapper">
            <div className="box-header">
              <Link title="Go back" to="/products/list">
                <i className="bx bxs-left-arrow-circle"></i>
              </Link>
              <h4 className="title-small mb">
                Product: {product.product_name}
              </h4>
            </div>
            <div className="row">
              <div className="col-5">
                <img src={`${BASE_URL}/${product.photo}`} alt="" />
              </div>
              <div className="col-7">
                <h4 className="title-small mb">Update image</h4>
                <div className="user-form">
                  <form
                    className="form"
                    onSubmit={updateProductPhoto}
                    autoComplete="off"
                    encType="multipart/form-data"
                  >
                    <TextInput
                      required="required"
                      label="Product photo"
                      name="photo"
                      type="file"
                      onChange={handleFileChange}
                      inputError={formErrors.photo}
                    />
                    <button
                      disabled={products.updateImage ? "disabled" : ""}
                      type="submit"
                      className="btn btn-success bx-pull-right"
                    >
                      {products.updateImage ? "Updating..." : "Update image"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="div-wrapper mb">
            <div className="user-form">
              <form className="form" onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-12">
                    <TextInput
                      required="required"
                      label="Product name"
                      name="product_name"
                      type="text"
                      value={inputValues.product_name}
                      onChange={handleChange}
                      inputError={formErrors.product_name}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <TextInput
                      required="required"
                      label="Product price"
                      name="product_price"
                      type="number"
                      value={inputValues.product_price}
                      onChange={handleChange}
                      inputError={formErrors.product_price}
                    />
                  </div>
                  <div className="col-6">
                    <SelectInput
                      label="Product category"
                      onChange={handleChange}
                      options={options}
                      name="category_id"
                      value={inputValues.category_id}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <SelectInput
                      label="Discount offers"
                      onChange={handleChange}
                      options={discountOptions}
                      name="discount_id"
                      value={inputValues.discount_id}
                    />
                  </div>
                  <div className="col-6">
                    <SelectInput
                      label="Product status"
                      onChange={handleChange}
                      options={statusOptions}
                      name="product_status"
                      value={inputValues.product_status}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TextAreaInput
                      required="required"
                      label="Product description"
                      name="product_desc"
                      value={inputValues.product_desc}
                      onChange={handleChange}
                      inputError={formErrors.product_desc}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      disabled={products.updateImage ? "disabled" : ""}
                      type="submit"
                      className="btn btn-success bx-pull-right"
                    >
                      {products.updateImage ? "Updating..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </DashboardWrapperMain>
        <DashboardWrapperRight>
          <div className="title-small mb">Overall information</div>
          <div className="mb">
            <OverallList data={overallData} />
          </div>
          <div className="title-small mb">Discounts & Prices</div>
          <div className="mb">
            <OverallList data={sellingData} />
          </div>
        </DashboardWrapperRight>
      </DashboardWrapper>
      <ToastContainer theme="dark" />
    </div>
  );
};

export default EditProduct;
