import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextInput from "../globals/TextInput";
import Button from "../globals/Button";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateUser } from "../../redux/users/userActions";

const EditCustomer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userState = useSelector((state) => state.users);
  const isUpdate = userState.updating;
  let user = userState.users.find((e) => e.id === Number(id));
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );

  const inputs = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    phone: user.phone,
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.firstname) {
      errors.firstname = "First name is required!";
    }
    if (!values.lastname) {
      errors.lastname = "Last name is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Please enter a valid email address!";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required!";
    }
    return errors;
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    if (Object.keys(formErrors).length === 0) {
      inputValues["xced_id"] = id;
      inputValues.token = token;
      dispatch(updateUser(inputValues));
    }
  };

  return (
    <div className="div-wrapper">
      <div className="box-header">
        <Link to="/customers/list">
          <i className="bx bxs-left-arrow-circle"></i>
        </Link>
        <div className="success txt-success">{userState.success}</div>
        <div className="error txt-danger">{userState.error}</div>
        <h3>Edit user</h3>
      </div>

      <form className="form" onSubmit={handleSubmit} autoComplete="off">
        <TextInput
          required="required"
          label="First name"
          name="firstname"
          type="text"
          value={inputValues.firstname}
          onChange={handleChange}
          inputError={formErrors.firstname}
        />
        <TextInput
          required="required"
          label="Last name"
          name="lastname"
          type="text"
          value={inputValues.lastname}
          onChange={handleChange}
          inputError={formErrors.lastname}
        />
        <TextInput
          required="required"
          label="Email"
          name="email"
          type="email"
          value={inputValues.email}
          onChange={handleChange}
          inputError={formErrors.email}
        />
        <TextInput
          required="required"
          label="Phone"
          name="phone"
          type="number"
          value={inputValues.phone}
          onChange={handleChange}
          inputError={formErrors.phone}
        />
        <Button
          class="btn btn-success bx-pull-right"
          type="submit"
          text={isUpdate ? "Updating" : "Update"}
          disabled={isUpdate ? "disabled" : ""}
        />
      </form>
      <div className="clearfix"></div>
      <div className="clearfix"></div>
    </div>
  );
};

export default EditCustomer;
