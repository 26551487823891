import React from "react";
import { Link } from "react-router-dom";

function OrdersMenu({ path }) {
  const menuList = [
    { id: 5, title: "All orders", route: "list" },
    { id: 0, title: "New orders", route: "new" },
    { id: 1, title: "Assigned orders", route: "assigned" },
    { id: 2, title: "On transit", route: "onprogress" },
    { id: 3, title: "Delivered orders", route: "delivered" },
    { id: 4, title: "Failed orders", route: "failed" },
  ];
  return (
    <div className="menuList">
      {menuList.map((item) => {
        return (
          <div
            key={item.id}
            className={
              item.route === path
                ? "menuList__links active_menu"
                : "menuList__links"
            }
          >
            <Link to={`/orders/${item.route}`}>{item.title}</Link>
          </div>
        );
      })}
    </div>
  );
}

export default OrdersMenu;
