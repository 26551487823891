import apiClient from "../ApiClient";
import { sideModalClose } from "../Index";
import {
  CLEAR_ORDERS_DATA,
  CONFIRM_DELIVERY_FAILURE,
  CONFIRM_DELIVERY_REQUEST,
  CONFIRM_DELIVERY_SUCCESS,
  DISPATCH_ORDER_FAILURE,
  DISPATCH_ORDER_REQUEST,
  DISPATCH_ORDER_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_PROCESSED_ORDERS_FAILURE,
  FETCH_PROCESSED_ORDERS_REQUEST,
  FETCH_PROCESSED_ORDERS_SUCCESS,
} from "./orderActionTypes";

// ------------------ SET THE ACTIONS --------------------------------
// set order fetch request
export const fetchOrdersRequest = () => {
  return {
    type: FETCH_ORDERS_REQUEST,
  };
};
// set order fetch success
export const fetchOrdersSuccess = (data) => {
  return {
    type: FETCH_ORDERS_SUCCESS,
    payload: data,
  };
};
// set order fetch failure
export const fetchOrdersFailure = (data) => {
  return {
    type: FETCH_ORDERS_FAILURE,
    payload: data,
  };
};

// dispatch request
export const dispatchOrderRequest = () => {
  return {
    type: DISPATCH_ORDER_REQUEST,
  };
};
// set success
export const dispatchOrderSuccess = (data) => {
  return {
    type: DISPATCH_ORDER_SUCCESS,
    payload: data,
  };
};
// set failure
export const dispatchOrderFailure = (data) => {
  return {
    type: DISPATCH_ORDER_FAILURE,
    payload: data,
  };
};

// Confirm delivery request
export const confirmDeliveryRequest = () => {
  return {
    type: CONFIRM_DELIVERY_REQUEST,
  };
};
// Confirm delivery success
export const confirmDeliverySuccess = (data) => {
  return {
    type: CONFIRM_DELIVERY_SUCCESS,
    payload: data,
  };
};
// Confirm delivery failure
export const confirmDeliveryFailure = (data) => {
  return {
    type: CONFIRM_DELIVERY_FAILURE,
    payload: data,
  };
};

// fetch processed orders
export const fetchProcessedOrdersRequest = () => {
  return {
    type: FETCH_PROCESSED_ORDERS_REQUEST,
  };
};
// Confirm delivery success
export const fetchProcessedOrdersSuccess = (data) => {
  return {
    type: FETCH_PROCESSED_ORDERS_SUCCESS,
    payload: data,
  };
};
// Confirm delivery failure
export const fetchProcessedOrdersFailure = (data) => {
  return {
    type: FETCH_PROCESSED_ORDERS_FAILURE,
    payload: data,
  };
};
// clear orders data .. messages
export const clearOrdersDataRequest = () => {
  return {
    type: CLEAR_ORDERS_DATA,
  };
};
// ------------------ DISPATCH ACTIONS TO REDUCER --------------------------------
// fetch the orders using axios

export const fetchOrders = () => {
  return (dispatch) => {
    dispatch(fetchOrdersRequest());

    return apiClient
      .get("/api/orders")
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(fetchOrdersSuccess(response.data.data));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchOrdersFailure(errorMsg));
      });
  };
};

// fetch PROCESSED ORDERS
export const fetchProcessedOrders = () => {
  return (dispatch) => {
    dispatch(fetchProcessedOrdersRequest());

    return apiClient
      .get("/api/fetchProcessedOrders")
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(fetchProcessedOrdersSuccess(response.data.data));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchProcessedOrdersFailure(errorMsg));
      });
  };
};

// dispatch order
export function dispatchOrder(orderId) {
  return function action(dispatch) {
    dispatch(dispatchOrderRequest);

    return apiClient
      .put("/api/dispatchOrder/" + orderId)
      .then((response) => {
        if (response.data.status === 200) {
          const success = response.data.message;
          dispatch(dispatchOrderSuccess(success));
          setTimeout(() => {
            dispatch(sideModalClose());
            dispatch(fetchOrders());
            dispatch(clearOrdersData());
          }, 2000);
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(dispatchOrderFailure(errorMsg));
      });
  };
}

// confirm delivery
export function confirmDelivery(orderId) {
  return function action(dispatch) {
    dispatch(confirmDeliveryRequest());

    return apiClient
      .put("/api/confirmDelivery/" + orderId)
      .then((response) => {
        if (response.data.status === 200) {
          const success = response.data.message;
          dispatch(confirmDeliverySuccess(success));
          setTimeout(() => {
            dispatch(sideModalClose());
            dispatch(fetchOrders());
            dispatch(clearOrdersData());
          }, 2000);
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(confirmDeliveryFailure(errorMsg));
      });
  };
}

// clear messages
export function clearOrdersData() {
  return function action(dispatch) {
    dispatch(clearOrdersDataRequest());
  };
}
