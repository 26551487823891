import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { images } from "../../constants";
import sidebarNav from "../../configs/sidebarNav";
import logo from "../../assets/images/logo.png";
import Modal from "../../components/modals/Modal";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/authActions";
import { toast, ToastContainer } from "react-toastify";

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );
  const userData = useSelector((state) =>
    state?.auth && state.auth ? state.auth : []
  );

  const [modal, setModal] = useState(false);
  const Toggle = () => setModal(!modal);

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const closeSidebar = () => {
    document.querySelector(".main__content").style.transform =
      "scale(1) translateX(0)";
    setTimeout(() => {
      document.body.classList.remove("sidebar-open");
      document.querySelector(".main__content").style = "";
      document
        .querySelector(".main__content")
        .classList.remove("hide_main_content");
    }, 500);
  };
  const hideMainContentOnMobile = () => {
    document.querySelector(".main__content").classList.add("hide_main_content");
  };
  const logoutAction = () => {
    dispatch(logout(token));
    setTimeout(() => {
      if (!userData.isLoggedIn) {
        navigate("/");
        toast.success(userData.success);
      }
    }, 3000);
  };

  const modalFooterItems = (
    <button onClick={logoutAction} className="submit">
      Continue
    </button>
  );

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={logo} alt="" />
        <div className="sidebar-close" onClick={closeSidebar}>
          <i className="bx bx-x"></i>
        </div>
      </div>
      <div className="sidebar__menu">
        {sidebarNav.map((nav, index) => (
          <Link
            to={nav.link}
            key={`nav-${index}`}
            className={`sidebar__menu__item ${
              activeIndex === index && "active"
            }`}
            onClick={closeSidebar}
          >
            <div className="sidebar__menu__item__icon">{nav.icon}</div>
            <div className="sidebar__menu__item__text">{nav.text}</div>
          </Link>
        ))}
        <div
          onClick={() => {
            // closeSidebar();
            Toggle();
            hideMainContentOnMobile();
          }}
          className="sidebar__menu__item"
        >
          <div className="sidebar__menu__item__icon">
            <i className="bx bx-log-out"></i>
          </div>
          <div className="sidebar__menu__item__text">Logout</div>
        </div>
      </div>
      {/* opens the logout modal */}
      <Modal
        show={modal}
        title="Logout"
        close={Toggle}
        actionBtn={modalFooterItems}
      >
        Are you sure you want to logout?
      </Modal>
      <ToastContainer theme="dark" />
    </div>
  );
};

export default Sidebar;
