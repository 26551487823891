import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextInput from "../globals/TextInput";
import Button from "../globals/Button";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateCategory } from "../../redux/product_categories/categoryActions";
import TextAreaInput from "../globals/TextAreaInput";

const EditCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const categoriesState = useSelector((state) => state.productCategories);
  const isUpdate = categoriesState.updating;
  let category = categoriesState.categories.find(
    (e) => e.category_id === Number(id)
  );
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );

  const inputs = {
    category_name: category.category_name,
    category_desc: category.category_desc,
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.category_name) {
      errors.category_name = "Category name is required!";
    }
    if (!values.category_desc) {
      errors.category_desc = "Description is required!";
    }
    return errors;
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    if (Object.keys(formErrors).length === 0) {
      inputValues["category_id"] = id;
      inputValues["token"] = token;
      dispatch(updateCategory(inputValues));
      window.setTimeout(function () {
        console.log(categoriesState.success);
        if (categoriesState.success) {
          navigate(-1);
        }
      }, 2000);
    }
  };

  return (
    <div className="div-wrapper">
      <div className="box-header">
        <Link to="/product-categories/list">
          <i className="bx bxs-left-arrow-circle"></i>
        </Link>
        <div className="success txt-success">{categoriesState.success}</div>
        <div className="error txt-danger">{categoriesState.error}</div>
        <h3>Edit category</h3>
      </div>

      <form className="form" onSubmit={handleSubmit} autoComplete="off">
        <TextInput
          required="required"
          label="Category name"
          name="category_name"
          type="text"
          value={inputValues.category_name}
          onChange={handleChange}
          inputError={formErrors.category_name}
        />
        <TextAreaInput
          required="required"
          label="Category description"
          name="category_desc"
          value={inputValues.category_desc}
          onChange={handleChange}
          inputError={formErrors.category_desc}
        />
        <Button
          class="btn btn-success bx-pull-right"
          type="submit"
          text={isUpdate ? "Updating" : "Update"}
          disabled={isUpdate ? "disabled" : ""}
        />
      </form>
      <div className="clearfix"></div>
      <div className="clearfix"></div>
    </div>
  );
};

export default EditCategory;
