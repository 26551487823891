import ReactDOM from "react-dom";
import "./Modal.scss";
// import Close from "./times-solid.svg";

const Modal = ({ show, close, title, children, actionBtn }) => {
  return (
    <div>
      <>
        {show ? (
          <div className="modalContainer" onClick={() => close()}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <header className="modal_header">
                <h2 className="modal_header-title"> {title} </h2>
                <button className="close" onClick={() => close()}>
                  <i className="bx bx-x"></i>
                </button>
              </header>
              <main className="modal_content"> {children} </main>
              <footer className="modal_footer">
                <button className="modal-close" onClick={() => close()}>
                  Cancel
                </button>

                {actionBtn}
              </footer>
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default Modal;
