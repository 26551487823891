import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./userinfo.scss";

const Userinfo = ({ userData, user }) => {
  const navigate = useNavigate();
  const isLoggedIn =
    userData?.isLoggedIn && userData?.isLoggedIn ? userData?.isLoggedIn : false;
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("login");
    }
  });
  return (
    <div className="user-info">
      <div className="user-info__img">
        <img src={user.img} alt="" />
      </div>
      <div className="user-info__name">
        <span>{userData?.userInfo?.firstname}</span>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { userData: state?.auth && state?.auth ? state?.auth : [] };
};

export default connect(mapStateToProps)(Userinfo);
