import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextInput from "../globals/TextInput";
import Button from "../globals/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateDiscount } from "../../redux/discounts/discountActions";
import SelectInput from "../globals/SelectInput";
import TextAreaInput from "../globals/TextAreaInput";

const EditDiscount = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const token = useSelector((state) =>
    state.auth.token ? state.auth.token : ""
  );
  const discountState = useSelector((state) => state.discounts);
  const isUpdate = discountState.updating;
  let discount = discountState.discounts.find(
    (e) => e.discount_id === Number(id)
  );

  const inputs = {
    discount_name: discount.discount_name,
    discount_percentage: discount.discount_percentage,
    discount_desc: discount.discount_desc,
    discount_status: discount.discount_status,
  };

  //  select input options
  const options = [
    { value: "0", label: "Inactive" },
    { value: "1", label: "Active" },
  ];

  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.discount_name) {
      errors.discount_name = "Discount name is required!";
    }
    if (!values.discount_percentage) {
      errors.discount_percentage = "Discount percentage is required!";
    }
    if (!values.discount_desc) {
      errors.discount_desc = "Description is required!";
    }
    return errors;
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    if (Object.keys(formErrors).length === 0) {
      inputValues["discount_id"] = id;
      inputValues["token"] = token;
      dispatch(updateDiscount(inputValues));
      window.setTimeout(function () {
        navigate(-1);
      }, 3000);
    }
  };

  return (
    <div className="div-wrapper">
      <div className="box-header">
        <Link to="/discounts/list">
          <i className="bx bxs-left-arrow-circle"></i>
        </Link>
        <div className="success txt-success">{discountState.success}</div>
        <div className="error txt-danger">{discountState.error}</div>
        <h3>Edit discount</h3>
      </div>

      <form className="form" onSubmit={handleSubmit} autoComplete="off">
        <TextInput
          required="required"
          label="Discount name"
          name="discount_name"
          type="text"
          value={inputValues.discount_name}
          onChange={handleChange}
          inputError={formErrors.discount_name}
        />
        <TextInput
          required="required"
          label="Discount percentage"
          name="discount_percentage"
          type="number"
          value={inputValues.discount_percentage}
          onChange={handleChange}
          inputError={formErrors.discount_percentage}
        />
        <SelectInput
          label="Discount status"
          onChange={handleChange}
          options={options}
          name="discount_status"
          value={inputValues.discount_status}
        />
        <TextAreaInput
          required="required"
          label="Discount description"
          name="discount_desc"
          value={inputValues.discount_desc}
          onChange={handleChange}
          inputError={formErrors.discount_desc}
        />
        <Button
          class="btn btn-success bx-pull-right"
          type="submit"
          text={isUpdate ? "Updating" : "Update"}
          disabled={isUpdate ? "disabled" : ""}
        />
      </form>
      <div className="clearfix"></div>
      <div className="clearfix"></div>
    </div>
  );
};

export default EditDiscount;
