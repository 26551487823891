import { CLOSE_ADD_SIDE_MODAL, OPEN_ADD_SIDE_MODAL } from "./modalActionTypes";

// 1. open side modal
export const openAddSideModal = () => {
  return {
    type: OPEN_ADD_SIDE_MODAL,
  };
};

// 2. close side modal
export const closeAddSideModal = () => {
  return {
    type: CLOSE_ADD_SIDE_MODAL,
  };
};

export function sideModalOpen() {
  return function action(dispatch) {
    dispatch(openAddSideModal());
  };
}

export function sideModalClose() {
  return function action(dispatch) {
    dispatch(closeAddSideModal());
  };
}
