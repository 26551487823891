import React from "react";
import "./selectInput.scss";

const SelectInput = (props) => {
  return (
    <div className="select">
      <select
        name={props.name}
        className="select-text"
        required={props.required ? "required" : ""}
        value={props.value ? props.value : ""}
        onChange={props.onChange}
      >
        {props.options.map((option) => (
          <option key={option.value + option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <span className="select-highlight"></span>
      <span className="select-bar"></span>
      <label className="select-label">{props.label}</label>
    </div>
  );
};

export default SelectInput;
