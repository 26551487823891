let state;

if (localStorage.getItem("persist:misanaAdmin") !== null) {
  if ("auth" in JSON.parse(localStorage.getItem("persist:misanaAdmin"))) {
    let data = JSON.parse(localStorage.getItem("persist:misanaAdmin"));
    state = JSON.parse(data?.auth).token;
  } else {
    state = null;
  }
} else {
  state = null;
}

export const token = state;
