import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  sideModalOpen,
  sideModalClose,
} from "../../redux/sideModal/modalActions";
import DataTable from "../../components/datatable/DataTable";
import Loader from "../../components/loader/Loader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import TextInput from "../../components/globals/TextInput";
import Button from "../../components/globals/Button";
import { Link } from "react-router-dom";
import TextAreaInput from "../../components/globals/TextAreaInput";
import SelectInput from "../../components/globals/SelectInput";
import { addproduct, fetchProducts } from "../../redux/products/productActions";
import { fetchDiscounts } from "../../redux/discounts/discountActions";
import { fetchCategories } from "../../redux/product_categories/categoryActions";
import RandomString from "../../components/globals/RandomString";
// noty
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CurrencyFormatter from "../../components/globals/CurrencyFormatter";

const ProductsList = ({
  products,
  categories,
  fetchProducts,
  fetchDiscounts,
  fetchCategories,
  sideModalState,
}) => {
  const dispatch = useDispatch();
  const [dataSaved, setDataSaved] = useState(false);
  const [productCategories, setproductCategories] = useState([]);

  useEffect(() => {
    if (products.dataSaved) {
      setDataSaved(true);
    }
    //set product categories
    let cats =
      categories?.categories && categories.categories
        ? categories.categories
        : [];
    setproductCategories(cats);
  }, [products, categories]);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchDiscounts();
  }, [fetchProducts, fetchCategories, fetchDiscounts]);

  const headers = [
    "SKU",
    "Product",
    "Category",
    "Price",
    "Stock",
    "Status",
    "Inventory",
    "Edit",
  ];

  const data = products.products;
  let tableRows = [];
  // get category name based on id
  const categoryName = (id) => {
    let category = productCategories.find((el) => el.category_id === id);
    return category?.category_name && category.category_name
      ? category.category_name
      : "";
  };

  if (data) {
    for (let i = 0; i < data.length; i++) {
      tableRows.push({
        product_SKU: data[i].product_SKU,
        product_name: data[i].product_name,
        category_id: categoryName(data[i].category_id),
        product_price: CurrencyFormatter(data[i].product_price, 2),
        products_available: data[i].products_available,
        product_status: data[i].product_status > 0 ? "Active" : "Inactive",
        inventory: (
          <Link to={`/products/inventory/${data[i].product_id}`}>
            <i className="bx bxs-shopping-bag font-14"></i>
          </Link>
        ),
        edit: (
          <Link to={`/products/edit-product/${data[i].product_id}`}>
            <i className="bx bx-edit font-14"></i>
          </Link>
        ),
      });
    }
  } else {
    tableRows = [];
  }

  // NOTIFICATIONS
  const notyfy = () => {
    toast.success("Success. Data has been saved");
  };
  const loading = (msg) => {
    toast.info(msg);
  };
  const errorNoty = (msg) => {
    toast.error(msg);
  };

  //  select input options
  let options = [{ value: "", label: "-- select --" }];

  if (productCategories) {
    for (let i = 0; i < productCategories.length; i++) {
      options.push({
        value: productCategories[i].category_id,
        label: productCategories[i].category_name,
      });
    }
  }

  const [selectedFile, setSelectedFile] = useState([]);

  const inputs = {
    product_SKU: RandomString(8),
    product_name: "",
    product_desc: "",
    product_price: "",
    category_id: "",
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [formErrors, setformErrors] = useState({});

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleFileChange = (event) => {
    setSelectedFile({ photo: event.target.files[0] });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(inputValues));
    loading("Saving, please wait...");

    if (Object.keys(formErrors).length === 0) {
      const formData = new FormData();
      formData.append("product_name", inputValues.product_name);
      formData.append("product_price", inputValues.product_price);
      formData.append("product_SKU", inputValues.product_SKU);
      formData.append("product_desc", inputValues.product_desc);
      formData.append("category_id", inputValues.category_id);
      formData.append("photo", selectedFile.photo);

      dispatch(addproduct(formData));
      if (dataSaved) {
        inputValues.product_SKU = RandomString(8);
        inputValues.product_name = "";
        inputValues.product_desc = "";
        inputValues.product_price = "";
        inputValues.category_id = "";
        setSelectedFile([]);
        dispatch(sideModalClose());
        notyfy();
        setDataSaved(false);
      }
      if (products.error !== "") {
        errorNoty(products.error);
      }
    }
  };

  // validate the form
  const validate = (values) => {
    const errors = {};
    if (!values.product_name) {
      errors.product_name = "product name is required!";
    }
    if (!values.product_price) {
      errors.product_price = "Product price is required!";
    }
    if (!values.product_desc) {
      errors.product_desc = "Product descriprion is required!";
    }
    if (!values.category_id) {
      errors.category_id = "Product category is required!";
    }
    if (!selectedFile) {
      errors.photo = "Product photo is required!";
    }
    return errors;
  };

  const openModal = () => {
    dispatch(sideModalOpen());
  };

  const displayContent = products.products ? (
    products.loading ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );
  return (
    <div>
      <div className="title">Products list</div>
      {displayContent}

      <SlidingPane
        className="sideModal"
        overlayClassName="sideModal-overlay"
        isOpen={sideModalState.modalOpen}
        title="Products"
        subtitle="Add a product"
        width="40%"
        onRequestClose={() => {
          dispatch(sideModalClose());
        }}
      >
        <div className="user-form">
          <form
            className="form"
            onSubmit={handleSubmit}
            autoComplete="off"
            encType="multipart/form-data"
          >
            <div>
              <h5>Product SKU: {inputValues.product_SKU} </h5>
            </div>
            <div className="clearfix"></div>
            <TextInput
              required="required"
              label="Product name"
              name="product_name"
              type="text"
              value={inputValues.product_name}
              onChange={handleChange}
              inputError={formErrors.product_name}
            />
            <TextInput
              required="required"
              label="Product price"
              name="product_price"
              type="number"
              value={inputValues.product_price}
              onChange={handleChange}
              inputError={formErrors.product_price}
            />
            <SelectInput
              label="Product category"
              onChange={handleChange}
              options={options}
              name="category_id"
              value={inputValues.category_id}
            />
            <TextAreaInput
              required="required"
              label="Product description"
              name="product_desc"
              value={inputValues.product_desc}
              onChange={handleChange}
              inputError={formErrors.product_desc}
            />
            <TextInput
              required="required"
              label="Product photo"
              name="photo"
              type="file"
              onChange={handleFileChange}
              inputError={formErrors.photo}
            />
            <Button
              class="btn btn-success bx-pull-right"
              type="submit"
              text={products.saving ? "Saving..." : "Save"}
              disabled={products.saving ? "disabled" : ""}
            />
          </form>
        </div>
      </SlidingPane>

      <button onClick={openModal} className="fab_container__button">
        <i className="bx bx-plus"></i>
      </button>
      <ToastContainer theme="dark" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state?.products && state.products ? state.products : [],
    categories:
      state?.productCategories && state.productCategories
        ? state.productCategories
        : [],
    discounts: state?.discounts && state.discounts ? state.discounts : [],
    sideModalState: state.sideModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProducts: () => dispatch(fetchProducts()),
    fetchCategories: () => dispatch(fetchCategories()),
    fetchDiscounts: () => dispatch(fetchDiscounts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
